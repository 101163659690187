import { styled } from "linaria/react";
import { TDirection } from "@lib/utils";

export const SentimentContainer = styled.div`
  width: 25px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SentimentScore = styled.div`
  padding: 6px;
  background: #fff;
  color: #000;
  font-size: 16px;
`;

export const AudioWrap = styled.div`
  background: rgb(0, 136, 0);
  height: 30px;
  border-radius: 15px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  padding: 0 10px;
  cursor: pointer;
`;

export const UploadWrap = styled.div`
  background: #eee;
  padding: 8px;
  margin-right: 15px;
`;

export const KeywordsContainer = styled.div`
  width: 250px;
  height: 100%;
  overflow: scroll;
  text-align: -webkit-center;
`;

export const Keyword = styled.div<{ level: number }>`
  width: 80%;
  height: 30px;
  border-radius: 15px;
  background: ${(props) => (props.level === 1 ? "#f8d7da" : props.level === 2 ? "#fff3cd" : "#d1ecf1")};
  margin: 0 0 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
`;

export const KeywordTimestamp = styled.div`
  display: inline;
  font-size: 8px;
  right: 10px;
  position: absolute;
  bottom: 8px;
`;

export const TranscribeWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #fff;
`;

export const TranscribeWrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  max-height: 100%;
  flex-grow: 1;
`;

export const TranscribeContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1;
  padding: 8px;
  > div {
    width: 100%;
  }
`;

export const TranscribeNote = styled.div<{ emphasized?: boolean }>`
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  font-style: ${(props) => (props.emphasized ? "italic" : "normal")};
  margin-bottom: ${(props) => (props.emphasized ? "0px" : "10px")};
`;

export const TranscribeStatus = styled.div<{
  direction: TDirection;
}>`
  font-size: 10px;
  white-space: nowrap;
`;

export const TranscribeText = styled.div<{
  direction: "outbound" | "inbound";
}>`
  display: flex;
  flex-direction: column;
  align-items: start;
  white-space: pre-wrap;
  width: 95%;
  transition: background-color 1000ms linear;
  align-self: ${(props) => (props.direction === "outbound" ? "flex-start" : "flex-start")};
  color: ${(props) => (props.direction !== "outbound" ? "#000" : "#b7b7b7")};

  &.transcript-item strong::first-letter {
    text-transform: uppercase;
  }

  &.highlight {
    background-color: #d6d8d9;
  }
`;

export const TranscribeTextHeader = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
  gap: 8px;
`;

export const TranscribeTextIdentity = styled.span`
  font-size: 13px;
  font-weight: bold;
  white-space: nowrap;
`;

export const TranslateWrap = styled.div``;
export const TranslatedText = styled.div`
  background: white;
  padding: 0 8px;
  font-size: 12px;
`;
